<template>
  <Dialog @hide="closeAddOrUpdate" style="width:707px " v-model:visible="show" :header="form.id==null?'添加新政解读':'编辑内容'">
    <div class="p-col-12">
      <div class="p-grid ">
        <div class="p-col-12 ">
          <div>
            <label>主题:</label>
            <InputText @blur="change" style="width:100%;" v-model="form.title" placeholder="输入主题"/>
            <span class="change">{{ mas }}</span>
          </div>
        </div>
        <div class="p-col-12">
          <div>
            <label>消息简介:</label>
            <InputText @blur="change1" style="width:100%;" v-model="form.brief" placeholder="简介"/>
            <span class="change">{{ mas1 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div>
        <label>内容：</label>
        <my-editor :value="form.content" v-on:input="setContent"></my-editor>
        <span class="change">{{ mas2 }}</span>
      </div>
    </div>
    <div class="p-grid p-col-12 p-mt-6 p-jc-around">
      <Button @click="submit()" :label="form.id==null?'确认发布':'确认修改'" class="p-mr-2" icon="pi pi-fw pi-send"/>
    </div>
  </Dialog>

</template>

<script>
import myEditor from "@/views/backend/myEditor";
export default {
  name: "addOrUpdatePolicy",
  components:{myEditor},
  data() {
    return {
      show:false,
      form: {
        id: null,
        brief: null,
        title: null,
        content: null,
        state: 2,
        allUser:0
      },
      mas: null,
      mas1: null,
      mas2: null
    }
  },
  methods: {
    init(data){
      if(data!=null){
        this.form=data
      }
      this.show=true
    },
    closeAddOrUpdate(){
      this.show=false
      this.$emit('close')
    },
    setContent(val){
      this.form.content = val
    },
    change(e) {
      let b = e.target.value.replace(/^ +| +$/g, '')
      if (b != e.target.value) {
        this.mas = "您输入格式有误，请重新输入"
      } else {
        this.mas = null;
      }
    },
    change1() {
      this.mas1 = null;
    },
    change2() {
      this.mas2 = null;
    },
    submit() {
      (this.form.title == null || this.form.title == "") ? this.mas = "必填项，请输入" : this.mas = null;
      (this.form.brief == null | this.form.brief == "") ? this.mas1 = "必填项，请输入" : this.mas1 = null;
      (this.form.content == null | this.form.content == "") ? this.mas2 = "必填项，请输入" : this.mas2 = null;
      if (this.form.title != null & this.form.title != ""
          & this.form.brief != null & this.form.brief != ""
          & this.form.content != null & this.form.content != "") {
        this.$http.post('/send/message', this.form).then(() => {
          this.show = false
          this.$toast.add({
            severity: 'success', summary: '成功', life: 3000
          })
        })
      }

    }
  }
}
</script>

<style scoped>

</style>