<template>
  <div class=" card ">
    <Breadcrumb :model="items"/>
    <DataTable class="p-mt-3" ref="dt" :value="messageList" v-model:selection="selectedProducts" dataKey="id"
               :auto-layout="true"
               :paginator="true" :rows="10"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
      <template #header>
        <div style="display: flex">
          <Button  @click="addOrUpdate(null)" label="添加新政解读" class="p-mr-2" icon="pi pi-plus"/>
          <InputText v-model="title" placeholder="搜索名称"/>

          <!--        <Dropdown class="p-ml-3" @change="getData"-->
          <!--                  v-model="industry"-->
          <!--                  :options="BelongIndustry" optionLabel="name" option-value="name"-->
          <!--                  placeholder="所属行业"-->
          <!--                  :showClear="true"/>-->
          <Calendar onfocus=this.blur() style="width: 22.5%" class="p-ml-3"
                    v-model="time" placeholder="发布日期"
                    @clear-click="getData"
                    dateFormat="yy-mm-dd" showButtonBar="true"/>
          <Button class="p-ml-2" @click="getData">查询</Button>
          <Button  @click="showInvitePeople" :class="{'p-disabled':selectedProducts.length==0}" label="政策解读人员"
                   class="p-ml-2"/>
        </div>
      </template>
      <Column selectionMode="multiple"></Column>
      <Column :sortable="false" field="title" header="消息名称"></Column>
      <Column field="time" header="发布时间" :sortable="true">
        <template #body="slotProps">
          {{
            new Date(+new Date(slotProps.data.time) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
          }}
        </template>
      </Column>
      <Column :sortable="false" field="brief" header="简介"></Column>
      <Column :exportable="false" field="operation" header="操作" headerStyle="width:25%;">
        <template #body="slotProps">
          <Button class="p-button-sm p-m-1" @click="addOrUpdate(slotProps.data)">编辑</Button>
          <Button :class="{'p-disabled':slotProps.data.allUser==1}" class="p-button-sm p-m-1"
                  @click="gotoView(slotProps.data)">解读人员列表
          </Button>
          <Button class="p-button-danger p-button-sm p-m-1" @click="confirmDeleteProduct(slotProps.data)">删除</Button>
        </template>
      </Column>
    </DataTable>
    <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="product">你确认要删除 <b>{{ product.title }}</b>?</span>
      </div>
      <template #footer>
        <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
        <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.id)"/>
      </template>
    </Dialog>
  </div>
<Toast/>
  <add-or-update-policy ref="addOrUpdate" v-if="show" v-on:close="closeAddOrUpdate"></add-or-update-policy>
  <InvitePeople v-on:close="showUserList=false" v-if="showUserList" ref="InvitePeople"></InvitePeople>
</template>

<script>
import InvitePeople from "@/views/modules/public/InvitePeople";

import AddOrUpdatePolicy from "@/views/platform/thinkTankManagement/addOrUpdatePolicy";
export default {
  components:{AddOrUpdatePolicy, InvitePeople},
  name: "",
  data() {
    return {
      title: null,
      mas: null,
      mas1: null,
      mas2: null,
      industry: null,
      time: null,
      BelongIndustry: [],
      selectedProducts: [],
      showUserList: false,
      fileUploadUrl: this.$global_msg.frontendUrl + "/activityRelease/upload",
      deleteProductDialog: false,
      product: {},
      show: false,
      show1: false,
      messageList: [],
      items: [
        {label: '智库推送', to: '/policyInterpretation'},
        {label: '政策解读', to: '/policyInterpretation'}
      ],
      problemsState: [
        {name: '待分配', code: '1'},
        {name: '待审核', code: '2'},
        {name: '已分配', code: '3'},
        {name: '已回复', code: '4'},
      ],
      industries: [
      ],
      selectedIndustry: null,
      problemStatus: null,
    }
  },
  mounted() {
    this.getData()
    this.getIndustry()
  },
  methods: {

    setContent(val ){
      this.sendMessage.content = val
    },
    closeAddOrUpdate(){
      this.show=false
      this.getData()
    },
    showInvitePeople(){
      this.showUserList=true
      this.$nextTick(()=>{
        this.$refs.InvitePeople.init(3,this.selectedProducts)
      })
    },
    getIndustry(){
      this.$http.get('/dictionary/selectValue',{params:{key:'suoshuhangye'}}).then((res)=>{
        let list =res.data.myValue.split(",");
        let resList=[]
        for(let i in list){
          let item ={}
          item.name=list[i]
          resList.push(item)
        }
        console.log(resList)
        this.BelongIndustry=resList;
      })
    },

    addOrUpdate(data) {
      this.show = true
      this.$nextTick(()=>{
        this.$refs.addOrUpdate.init(data)
      })
    },

    getData() {
      this.$http.get('/send/sendMessageList', {
        params: {
          state: 2,
          title: this.title,
          addUser: this.name,
          industry: this.industry,
          addTime: this.time,
        }
      }).then((res) => {
        this.messageList = res.data
        console.log(res.data)
      })
    },
    gotoView(data) {
      this.$router.push('/participants?id=' + data.id + '&tableType=3')
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },


    deleteProduct(id) {
      this.$http.get('/send/remove', {params: {'id': id}}).then(() => {
        this.deleteProductDialog = false;

        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        setTimeout(() => {
          this.getData();
        }, 1000)
      })
    },
    /*goto(id) {
      this.$router.push('/newsDetails?id=' + id)
    },*/
  }
}
</script>

<style scoped>
label {
  display: inline-block;
  width: 80px
}

.change {
  color: red;
}
</style>
